<template>
	<div class="license">
		<img src="@/assets/img/license.png" alt="">
	</div>
</template>

<script>
	export default {
		name: 'situation',
	}
</script>

<style  scoped="scoped">
	.license{
		margin: 0 auto 80px;
		text-align: center;
	}
	.license img {
		width: 600px;
	}
</style>
