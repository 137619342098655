export const about_us_situation = {
  cn: [
    `家乡互动科技有限公司是中国领先的移动游戏开发商及运营商， 特别擅长本地化麻将及扑克游戏。集团在"公平、专业、安全、 创新"的价值观下深耕本地化棋牌赛道多年。集团旗下棋牌类游戏按照玩法数量计算，在中国排名第一，实现了从 以东北为主的区域市场到全国性市场的转变。除了在棋牌领域深耕外， 集团亦开发运营休闲游戏，目前已在国内外上线多款休闲游戏。 公司近年通过差异化运营和本地化策略，用户基数实现了跨越式增长， 已成功建立稳定且健康发展的自有平台流量。公司未来将继续致力于 为广大客户提供安全可靠、类型丰富、画面优美的游戏体验。`,
    "2019年7月4日 - 家乡互动科技有限公司在港交所上市（股票编号：03798）。",
    `未来，家乡互动将继续秉承"公平化、专业化、人性化"的宗旨，倡导"绿色健康生活，远离赌博"的理念，真正为棋牌爱好者量身打造高品质的棋牌网娱健康生活引导，逐步成为中国绿色健康兼具公平性的网络游戏平台。`,
  ],
  en: [
    `Homeland Interactive Technology Limited. is China's leading mobile game developer and operator, especially good at localized mahjong and poker games. The group has been deeply involved in the localized chess and card industry for many years under the values of "fairness, professionalism, safety and innovation".
    The group's chess and card games rank first in China based on the number of games played, realizing the transformation from a regional market mainly in Northeast China to a national market. In addition to its deep involvement in the field of chess and card games, the Group also develops and operates casual games, and has currently launched a number of casual games at home and abroad. In recent years, the company has achieved leapfrog growth in its user base through differentiated operations and localization strategies, and has successfully established stable and healthy development of its own platform traffic. In the future, the company will continue to be committed to providing our customers with a safe and reliable gaming experience with rich types and beautiful graphics. `,
    "On July 4, 2019, Homeland Interactive Technology Limited.was listed on the Hong Kong Stock Exchange (stock number: 03798).",
    `In the future, Hometown Interactive will continue to adhere to the principles of "fairness, professionalism, and humanization", advocate the concept of "green and healthy life, away from gambling", and truly tailor high-quality online entertainment and healthy life guidance for chess and card enthusiasts, gradually becoming a green, healthy, and fair online gaming platform in China.`
  ],
  tcn: [
    `家鄉互動科技有限公司是中國領先的移動遊戲開發商及運營商，特別擅長當地語系化麻將及撲克遊戲。 集團在“公平、專業、安全、創新”的價值觀下深耕當地語系化棋牌賽道多年。集團旗下棋牌類遊戲按照玩法數量計算，在中國排名第一，實現了從以東北為主的區域市場到全國性市場的轉變。 除了在棋牌領域深耕外，集團亦開發運營休閒遊戲，現時已在國內外上線多款休閒遊戲。 公司近年通過差异化運營和當地語系化策略，用戶基數實現了跨越式增長，已成功建立穩定且健康發展的自有平臺流量。 公司未來將繼續致力於為廣大客戶提供安全可靠、類型豐富、畫面優美的遊戲體驗。`,
    "2019年7月4日 - 家鄉互動科技有限公司在港交所上市（股票編號：03798）。",
    `未來，家鄉互動將繼續秉承「公平化、專業化、人性化」的宗旨，宣導「綠色健康生活，遠離賭博」的理念，真正為棋牌愛好者量身打造高品質的棋牌網娛健康生活引導，逐步成為中國綠色健康兼具公平性的網路遊戲平臺。`
  ],
};


export const company = {
  cn: [
    {
      title: "公司名称",
      content: "家乡互动科技有限公司",
    },
    {
      title: "股票代码",
      content: "03798.HK",
    },
    {
      title: "总部",
      content: "香港铜锣湾希慎道33号利园一 期2451室",
    },
    {
      title: "大陆主要营业地点",
      content: "中国厦门思明区观音山台南路77号汇金国际中心大厦写字楼7A层",
    },
    {
      title: "香港主要营业地点",
      content: "香港铜锣湾希慎道33号利园一 期2451室",
    },
    {
      title: "香港证券登记处",
      content: "卓佳证券登记有限公司",
    },
  ],
  tcn: [
    {
      title:"公司名稱",
      content:"家鄉互動科技有限公司",
      },
      {
      title:"股票代碼",
      content:"03798.HK",
      },
      {
      title:"總部",
      content:"香港銅鑼灣希慎道33號利園一期2451室",
      },
      {
      title:"大陸主要營業地點",
      content:"中國廈門思明區觀音山台南路77號匯金國際中心塔樓辦公大樓7A層",
      },
      {
      title:"香港主要營業地點",
      content:"香港銅鑼灣希慎道33號利園一期2451室",
      },
      {
      title:"香港證券登記處",
      content:"卓佳證券登記有限公司",
      }
  ],
  en: [
    {
      title:"Company Name",
      content:"Homeland Interactive Technology Limited",
      },
      {
      title:"Stock Code",
      content:"03798.HK",
      },
      {
      title:"Headquarters",
      content:"Room 2451, Lee Garden One, 33 Hysan Road, Causeway Bay, Hong Kong",
      },
      {
      title:"Main business locations in mainland China",
      content:"7A/F, Huijin International Center Building, No. 77 Guanyinshantai South Road, Siming District, Xiamen, China",
      },
      {
      title:"Principal Place of Business in Hong Kong",
      content:"Room 2451, Lee Garden One, 33 Hysan Road, Causeway Bay, Hong Kong",
      },
      {
      title:" Hong Kong Branch Share Registrar",
      content:"TRICOR INVESTOR SERVICES LIMITED.",
      }
  ],
};

export const board = {
  cn: {
    title: "董事会",
    list: [
      {
        name: "吴承泽",
        title: "主席兼执行董事",
        content:
          "吴承泽先生为本集团创办人、主席及本公司执行董事。吴先生主要负责制订、执行本集团整体发展策略及业务规划，监管本集团整体发展及运营。彼于2018年5月7日获委任为董事并自2018年9月20日起担任主席兼首席执行官职务。彼于本集团历任多个其他职务，包括于2014年之前任本集团首席营运官及自2015年9月起任家乡互动（厦门）网络科技有限公司总经理。吴先生在游戏行业积逾8年经验。创办本集团之前，吴先生于2006年8月至2009年9月在长春安信电力科技有限公司任程序员。吴先生于2009年7月取得中国吉林大学法学硕士学位。",
      },
      {
        name: "丁春龙",
        title: "企业顾问兼执行董事",
        content: "丁春龙先生，35岁，于中国长春理工大学获得专业证书。丁先生于游戏营运及新媒体营销方面有逾6年经验，并担任本集团的顾问。由二零一七年三月至二零二二年六月，丁先生于吉林省宇柯网络科技有限公司担任顾问，负责该公司的整体营运。自二零二二年七月起，丁先生一直担任豫泰（深圳）网路科技有限公司的顾问，主要负责本集团的新媒体营运、现场娱乐及专题视频制作业务的应用及研究。",
      },
      {
        name: "汤英浩",
        title: "首席财务官兼执行董事",
        content: "汤英浩先生，36岁，于二零零八年、二零零九年及二零一零年从澳大利亚国立大学分别获授商业（会计及金融）学士学位、商学硕士学位以及政府及商业法律硕士学位。汤先生于二零一一年至二零一六年在澳洲国民银行财务规划部担任营运总监，于二零一五年至二零一八年在澳洲国民银行业务部担任亚洲投资总监。彼后来加入家乡互动科技有限公司担任财务总监，并于二零二零年九月二十八日获委任为首席财务官。汤先生协助本集团完成于二零一九年七月四日在香港联合交易所主板上市。自此，董事会接连委任汤先生担任本集团两间香港附属公司（即家乡互娱有限公司及家乡互动互娱有限公司）的董事。",
      },
      {
        name: "张玉国",
        title: "独立非执行董事",
        content:
          "张玉国先生于2019年6月5日获委任为独立非执行董事，任期为自上市日期起3年，负责向董事会提供独立意见。张先生自1996年3月起就职于吉林大学，现任中国吉林大学东北亚研究院副教授。张先生于2008年6月取得吉林大学行政学院法学博士学位。",
      },
      {
        name: "胡洋洋",
        title: "独立非执行董事",
        content:
          "胡洋洋先生于2019年6月5日获委任为独立非执行董事，任期为自上市日期起3年，负责向董事会提供独立意见。胡先生自2015年7月起任平安证券有限公司投资银行部门经理，主要就首次公开发售及企业融资有关事项提供意见。加入平安证券有限公司前，胡先生于2012年10月至2015年3月任职于安永华明上海分所金融服务组织，主要负责进行财务审计。胡先生于2012年6月取得中国上海交通大学的工商管理学士学位，并于2018年6月取得上海财经大学的经济学硕士学位。胡先生自2017年3月起为中国注册会计师协会(CICPA) 的非执业会员，并自2017年3月起成为特许公认会计师公会(ACCA) 会员。",
      },
      {
        name: "郭莹",
        title: "独立非执行董事",
        content: "郭莹女士，62岁，为本公司独立非执行董事，负责向董事会提供独立建议。郭女士于1983年3月至1999年4月在吉林热电厂任工程部项目助理。于1999年5月至2005年10月，郭女士于长春名门饭店（现称长春海航名门酒店）人力资源部担任培训经理。于2005年11月至2016年12月，郭女士担任吉林省电力科学研究院有限公司人力资源部招聘经理。郭女士于1998年在中国获得东北电力学院（现称东北电力大学）热能动力工程学士学位。",
      },
    ],
  },
  tcn: {
    title: "董事會",
    list: [
      {
        "name": "吳承澤",
        "title": "主席、首席執行官兼執行董事",
        "content": "吳承澤先生為本集團創辦人、主席兼首席執行官及本公司執行董事。吳先生主要負責制訂、執行本集團整體發展策略及業務規劃，監管本集團整體發展及運營。彼於2018年5月7日獲委任為董事並自2018年9月20日起擔任主席兼首席執行官職務。彼於本集團歷任多個其他職務，包括於2014年之前任本集團首席營運官及自2015年9月起任家鄉互動（廈門）網絡科技有限公司總經理。吳先生在遊戲行業積逾8年經驗。創辦本集團之前，吳先生於2006年8月至2009年9月在長春安信電力科技有限公司任程序員。吳先生於2009年7月取得中國吉林大學法學碩士學位。"
      },
      {
        "name": "丁春龍",
        "title": "企業顧問兼執行董事",
        "content": "丁春龍先生，35歲，於中國長春理工大學獲得專業證書。 丁先生於遊戲營運及新媒體行銷方面有逾6年經驗，並擔任本集團的顧問。 由二零一七年三月至二零二二年六月，丁先生於吉林省宇柯網絡科技有限公司擔任顧問，負責該公司的整體營運。 自二零二二年七月起，丁先生一直擔任豫泰（深圳）網路科技有限公司的顧問，主要負責本集團的新媒體營運、現場娛樂及專題視頻製作業務的應用及研究。"
      },
      {
        "name": "湯英浩",
        "title": "首席財務官兼執行董事",
        "content": "湯英浩先生，36歲，於二零零八年、二零零九年及二零一零年從澳大利亞國立大學分別獲授商業（會計及金融）學士學位、商學碩士學位以及政府及商業法律碩士學位。 湯先生於二零一一年至二零一六年在澳大利亞國民銀行財務規劃部擔任營運總監，於二零一五年至二零一八年在澳大利亞國民銀行業務部擔任亞洲投資總監。 彼後來加入家鄉互動科技有限公司擔任財務總監，並於二零二零年九月二十八日獲委任為首席財務官。 湯先生協助本集團完成於二零一九年七月四日在香港聯合交易所主機板上市。 自此，董事會接連委任湯先生擔任本集團兩間香港附屬公司（即家鄉互娛有限公司及家鄉互動互娛有限公司）的董事。"
      },
      {
        "name": "張玉國",
        "title": "獨立非執行董事",
        "content": "張玉國先生於2019年6月5日獲委任為獨立非執行董事，任期為自上市日期起3年，負責向董事會提供獨立意見。 張先生自1996年3月起就職於吉林大學，現任中國吉林大學東北亞研究院副教授。 張先生於2008年6月取得吉林大學行政學院法學博士學位。"
      },
      {
        "name": "胡洋洋",
        "title": "獨立非執行董事",
        "content": "胡洋洋先生於2019年6月5日獲委任為獨立非執行董事，任期為自上市日期起3年，負責向董事會提供獨立意見。 胡先生自2015年7月起任平安證券有限公司投資銀行部門經理，主要就首次公開發售及企業融資有關事項提供意見。 加入平安證券有限公司前，胡先生於2012年10月至2015年3月任職於安永華明上海分所金融服務組織，主要負責進行財務審計。 胡先生於2012年6月取得中國上海交通大學的工商管理學士學位，並於2018年6月取得上海財經大學的經濟學碩士學位。 胡先生自2017年3月起為中國註冊會計師協會（CICPA）的非執業會員，並自2017年3月起成為特許公認會計師公會（ACCA）會員。"
      },
      {
        "name": "郭瑩",
        "title": "獨立非執行董事",
        "content": "郭瑩女士，62歲，為本公司獨立非執行董事，負責向董事會提供獨立建議。 郭女士於1983年3月至1999年4月在吉林熱電廠任工程部項目助理。 於1999年5月至2005年10月，郭女士於長春名門飯店（現稱長春海航名門飯店）人力資源部擔任培訓經理。 於2005年11月至2016年12月，郭女士擔任吉林省電力科學研究院有限公司人力資源部招聘經理。 郭女士於1998年在中國獲得東北電力學院（現稱東北電力大學）熱能動力工程學士學位。"
      },
    ]
  },
  en: {
    list:[
      {
        "name": "Wu Chengze (吳承澤)",
        "title": "Chairman and executive Director",
        "content": " Mr. Wu Chengze (吳承澤) is a Founder, the Chairman and  an executive Director of our Company. Mr. Wu is primarily responsible for formulating and implementing the overall development strategies and business plans of our Group and overseeing the overall development and operations of our Group. He was appointed as a Director on May 7, 2018 and his position as Chairman and Chief Executive Officer took effect on September 20, 2018. He has held various other positions in our Group, including chief operating officer of our Group prior to 2014 and the general manager of Jiaxiang Interactive (Xiamen) Network Technology Company Limited since September 2015. Mr. Wu has over eight years of experience in the game industry. Prior to founding our Group, Mr. Wu worked at Changchun Anxin Power Technology Co., Ltd. (長春安信電力科技有限公司) as a programmer from August 2006 to September 2009. Mr. Wu completed his master’s degree in law in July 2009 at Jilin University (吉林大學) in the PRC."
      },
      {
        "name": "Ding Chunlong (丁春龍)",
        "title": "Corporate Advisor and Executive Director",
        "content": "Mr. Ding, aged 35, obtained his certificate of specialization from Changchun University of  Technology (長春理工大學) in the PRC. Mr. Ding has over 6 years of experience in game  operation and new media marketing and has served as a consultant of the Group. From  March 2017 to June 2022, Mr. Ding worked with Jilin Yuke Network Technology Company  Limited as a consultant, and was responsible for the company’s overall operation. Since July  2022, Mr. Ding has been a consultant at Yutai (Shenzhen) Network Technology Co., Ltd.*  (豫泰(深圳)網路科技有限公司), primarily responsible for the application and research of  the Group’s new media operation, live entertainment and infomercial short video production  business."
      },
      {
        "name": "Tang Yinghao (湯英浩)",
        "title": "Chief Operating Officer and executive Director",
        "content": "Mr. Tang Mr. Tang, aged 36, obtained the Bachelor’s degree in Commerce (Accounting and Finance)  as well as Master’s degrees in Business and Government and Commercial Law from the  Australian National University in 2008, 2009 and 2010, respectively. Mr. Tang served at  the National Australian Bank Financial Planning as Head of Operations from 2011 to 2016  and worked with National Australian Bank Business as Asian Investment Lead from 2015  to 2018. He later joined Homeland Interactive Technology Ltd. as the financial director and  has been appointed as the Chief Financial Officer on 28 September 2020. Mr. Tang assisted  the Group to complete the listing on the Main Board of the Hong Kong Stock Exchange  on 4 July 2019. Since then, the Board has successively appointed Mr. Tang as a director of  two Hong Kong subsidiaries of the Group, namely Homeland Entertainment & Technology  Limited (家鄉互娛有限公司) and Homeland Interactive Entertainment & Technology  Limited (家鄉互動互娛有限公司). "
      },
      {
        "name": "Zhang Yuguo (張玉國)",
        "title": "Independent non-executive Director",
        "content": "Mr. Zhang Yuguo (張玉國) was appointed as our independent non-executive Director on June 5, 2019 for a fixed term of three years commencing from the Listing Date, and is responsible for providing independent advice to our Board. Mr. Zhang has worked in Jilin University since March 1996 and currently serves an Associate Professor of the Northeast Asian Studies College of Jilin University (吉林大學東北亞研究院) in the PRC. Mr. Zhang obtained his doctor of law degree from the College of Administration of Jilin University (吉林大學行政學院) in June 2008."
      },
      {
        "name": "Hu Yangyang (胡洋洋)",
        "title": "Independent non-executive Director",
        "content": "Mr. Hu Yangyang (胡洋洋) was appointed as our independent non-executive Director on June 5, 2019 for a fixed term of three years commencing from the Listing Date, and is responsible for providing independent advice to our Board. Mr. Hu serves as an associate of the Investment Banking Division of Ping An Securities Co., Ltd. since July 2015, primarily advising on initial public offerings and other corporate finance related matters. Prior to joining Ping An Securities Co., Ltd., Mr. Hu worked at the Financial Services Organization of Ernst & Young Hua Ming LLP Shanghai Branch from October 2012 to March 2015 and was mainly responsible for conducting financial audits. Mr. Hu received his bachelor’s degree in business administration from Shanghai Jiao Tong University (上海交通大學) in the PRC in June 2012 and obtained his master’s degree in economics from Shanghai University of Finance and Economics (上海財經大學) in June 2018. Mr. Hu has been a non-practising member of The Chinese Institute of Certified Public Accountants (CICPA) since March 2017 and became a member of The Association of Chartered Certified Accountants (ACCA) since March 2017."
      },
      {
        "name": "Guo Ying (郭瑩)",
        "title": "Independent non-executive director",
        "content": "Ms. Guo Ying (郭瑩), aged 62, is an independent non-executive director of the Company  and is responsible for providing independent advice to the Board. Ms. Guo worked as a  project assistant in the engineering department at Jilin Cogeneration Plant from March 1983  to April 1999. She was a training manager of human resources department at Changchun  Noble Hotel (currently known as Hainan Airline Hotel Noble Changchun) from May 1999 to  October 2005. From November 2005 to December 2016, Ms. Guo served as the recruitment  manager of human resources department at Jilin Electric Power Research Institute Co.,  Ltd.. Ms. Guo obtained her bachelor’s degree in thermal power engineering from Northeast  Electric Power College (東北電力學院) (currently known as Northeast Electric Power  University (東北電力大學)) in the PRC in 1998"
      },
    ],
    title: 'Board of Directors'
  },
};

export const management_team = {
  cn: {
    title: "管理团队",
    list: [
      {
        name: "吴承泽",
        head_url: "wuchengze",
        title: "主席兼执行董事",
        sketch:
          "吴先生主要负责制订、执行本集团整体发展策略及业务规划，监管本集团整体发展及运营。",
        content:
          "吴承泽先生为本集团创办人、主席兼首席执行官及本公司执行董事。吴先生主要负责制订、执行本集团整体发展策略及业务规划，监管本集团整体发展及运营。彼于2018年5月7日获委任为董事并自2018年9月20日起担任主席兼首席执行官职务。彼于本集团历任多个其他职务，包括于2014年之前任本集团首席营运官及自2015年9月起任家乡互动（厦门）网络科技有限公司总经理。吴先生在游戏行业积逾8年经验。创办本集团之前，吴先生于2006年8月至2009年9月在长春安信电力科技有限公司任程序员。吴先生于2009年7月取得中国吉林大学法学硕士学位。",
      },
      {
        name: "汤英浩",
        head_url: "",
        title: "首席财务官兼执行董事",
        sketch: "",
        content: "汤英浩先生，36岁，于二零零八年、二零零九年及二零一零年从澳大利亚国立大学分别获授商业（会计及金融）学士学位、商学硕士学位以及政府及商业法律硕士学位。汤先生于二零一一年至二零一六年在澳洲国民银行财务规划部担任营运总监，于二零一五年至二零一八年在澳洲国民银行业务部担任亚洲投资总监。彼后来加入家乡互动科技有限公司担任财务总监，并于二零二零年九月二十八日获委任为首席财务官。汤先生协助本集团完成于二零一九年七月四日在香港联合交易所主板上市。自此，董事会接连委任汤先生担任本集团两间香港附属公司（即家乡互娱有限公司及家乡互动互娱有限公司）的董事。",
      },
      {
        name: "丁春龙",
        head_url: "",
        title: "企业顾问兼执行董事",
        sketch: "",
        content: "丁春龙先生，35岁，于中国长春理工大学获得专业证书。丁先生于游戏营运及新媒体营销方面有逾6年经验，并担任本集团的顾问。由二零一七年三月至二零二二年六月，丁先生于吉林省宇柯网络科技有限公司担任顾问，负责该公司的整体营运。自二零二二年七月起，丁先生一直担任豫泰（深圳）网路科技有限公司的顾问，主要负责本集团的新媒体营运、现场娱乐及专题视频制作业务的应用及研究",
      },
      {
        name: "崔玮",
        head_url: "",
        title: "首席执行官",
        sketch: "",
        content: "崔玮女士，41岁，自2021年5月7日至2023年9月4日担任本公司联席公司秘书。崔女士于2021年3月加入本集团，担任本集团的首席战略官，并于2021年5月被委任为联席公司秘书，负责本公司的资本市场及投资并购等事宜，2022年起兼任本集团深圳分公司总经理，主导国内及海外创新游戏研发等多个业务板块。2014年至2020年，崔女士于芒果文创基金(易泽资本)从事私募股权投资，担任高级副总裁职务。 2007年至2014年，崔女士于湖南广播电视台广告中心任职。崔女士于2007年获得香港中文大学传播学哲学硕士学位，于2004年获得中国传媒大学新闻学学士学位。",
      },
      {
        name: "苏波",
        head_url: "subo",
        title: "首席投资官",
        sketch: "苏先生主要负责监管本集团策略发展及扩张规划。",
        content:
          "苏波先生为本集团创办人兼首席投资官。苏先生主要负责监管本集团策略发展及扩张规划。彼于2018年5月7日获委任为董事并自2018年9月20日起担任首席投资官职务。彼于本集团历任多个其他职务，包括本集团监督财务、法务及行政事务的负责人及自2018年2月起任本集团首席投资官。苏先生在游戏行业积逾8年经验。创办本集团之前，苏先生于2007年7月至2009年11月在长春安信电力科技有限公司任程序员。自2017年9月起，苏先生亦在长春工业大学担任研究生导师。苏先生于2007年6月透过远程学习完成中国吉林大学计算机软件专业本科学业。",
      },
      {
        name: "郭顺顺",
        head_url: "guoshunshun",
        title: "首席产品官",
        sketch: "郭先生主要负责监督游戏开发及制作。",
        content:
          "郭顺顺先生为本集团首席产品官及本公司执行董事。郭先生主要负责监督游戏开发及制作。彼于2012年12月加入本集团并于本集团历任多个职务，包括自2012年12月起任吉林省鑫泽网络技术有限公司的游戏设计及开发主管及自2015年9月起任职于家乡互动（厦门）网络科技有限公司棋牌游戏业务部之董事。彼于2018年9月20日获委任为董事并自同日起担任首席产品官职务。郭先生在游戏行业积逾8年经验。加入本集团之前，郭先生于2009年11月至2010年4月在吉林省睿网科技股份有限公司任程序员。",
      },
      {
        name: "李伟",
        head_url: "liwei",
        title: "董事长顾问",
        sketch:
          "",
        content:
          "李伟先生为本集团董事长顾问。彼负责协助本集团首席执行官执行本集团的整体发展策略及业务规划。李先生于2013年8月加入本集团并于本集团历任多个职务，包括吉林省鑫泽网络技术有限公司的营销总监。彼积逾12年销售管理经验。加入本集团之前，李先生于2005年5月至2006年12月任北京易彩互联科技有限公司的销售经理。2007年1月至2008年3月，彼为亚洲金控（北京）投资有限公司的销售发展董事。2010年12月至2013年7月，李先生于耐特康赛网络技术（北京）有限公司任业务发展董事。李先生于2007年1月取得北京物资学院的电子商务文凭。",
      },
      {
        name: "吴介然",
        head_url: "",
        title: "投资总监兼联席公司秘书",
        sketch: "",
        content: "吴介然先生，34岁，于2021年3月加盟本集团担任战略投资部的投资总监，负责本公司的资本市场事务、策略投资与收购、财务投资及被投企业财务法务综合管理。吴先生在财务管理、策略投资及风险管理领域拥有12年的工作经验。于加盟本集团前，吴先生曾担任易泽资本管理有限公司的风控总监，负责公司拟投资项目的尽调及风险评估工作，以及全面管理被投企业的财务、法务事宜。于2011年至2016年期间，彼于普华永道上海分所担任审计部经理，基于新旧中国企业会计准则、国际及香港企业会计准则、美国会计准则等，为美股、港股上市公司、并购交易标的公司及拟上市公司提供审计服务。吴先生于2011年取得上海财经大学的管理学学士学位。于2016年，彼通过中国证券投资基金业协会组织的基金从业人员资格考试。于2017年成为中国注册会计师协会会员。",
      },
    ],
  },
  tcn: {
    title: "管理團隊",
    list:[
      {
        "name": "吳承澤",
        "head_url": "wuchengze",
        "title": "主席、首席執行官兼執行董事",
        "sketch": "吳先生主要負責制訂、執行本集團整體發展策略及業務規劃，監管本集團整體發展及運營。",
        "content": "吳承澤先生為本集團創辦人、主席兼首席執行官及本公司執行董事。吳先生主要負責制訂、執行本集團整體發展策略及業務規劃，監管本集團整體發展及運營。彼於2018年5月7日獲委任為董事並自2018年9月20日起擔任主席兼首席執行官職務。彼於本集團歷任多個其他職務，包括於2014年之前任本集團首席營運官及自2015年9月起任家鄉互動（廈門）網絡科技有限公司總經理。吳先生在遊戲行業積逾8年經驗。創辦本集團之前，吳先生於2006年8月至2009年9月在長春安信電力科技有限公司任程序員。吳先生於2009年7月取得中國吉林大學法學碩士學位。"
      },
     
      {
        "name": "湯英浩",
        "head_url": "",
        "title": "首席營運官兼執行董事",
        "sketch": "",
        "content": "湯英浩先生，36歲，於二零零八年、二零零九年及二零一零年從澳大利亞國立大學分別獲授商業（會計及金融）學士學位、商學碩士學位以及政府及商業法律碩士學位。 湯先生於二零一一年至二零一六年在澳大利亞國民銀行財務規劃部擔任營運總監，於二零一五年至二零一八年在澳大利亞國民銀行業務部擔任亞洲投資總監。 彼後來加入家鄉互動科技有限公司擔任財務總監，並於二零二零年九月二十八日獲委任為首席財務官。 湯先生協助本集團完成於二零一九年七月四日在香港聯合交易所主機板上市。 自此，董事會接連委任湯先生擔任本集團兩間香港附屬公司（即家鄉互娛有限公司及家鄉互動互娛有限公司）的董事。"
      },
      {
        "name": "丁春龍",
        "head_url": "",
        "title": "企業顧問兼執行董事",
        "sketch": "門先生主要負責監督本集團的私人房卡遊戲業務。",
        "content": "丁春龍先生，35歲，於中國長春理工大學獲得專業證書。 丁先生於遊戲營運及新媒體行銷方面有逾6年經驗，並擔任本集團的顧問。 由二零一七年三月至二零二二年六月，丁先生於吉林省宇柯網絡科技有限公司擔任顧問，負責該公司的整體營運。 自二零二二年七月起，丁先生一直擔任豫泰（深圳）網路科技有限公司的顧問，主要負責本集團的新媒體營運、現場娛樂及專題視頻製作業務的應用及研究。"

      },
      {
        "name": "崔瑋",
        "head_url": "",
        "title": "首席執行官",
        "sketch": "",
        "content": "<p>崔瑋女士，41歲，自2021年5月7日至2023年9月4日擔任本公司聯席公司秘書。 崔女士於2021年3月加入本集團，擔任本集團的首席戰畧官，並於2021年5月被委任為聯席公司秘書，負責本公司的資本市場及投資並購等事宜，2022年起兼任本集團深圳分公司總經理，主導國內及海外創新遊戲研發等多個業務板塊。</ p><br/>2014年至2020年，崔女士於芒果文創基金（易澤資本）從事私募股權投資，擔任高級副總裁職務。 2007年至2014年，崔女士於湖南廣播電視臺廣告中心任職。 崔女士於2007年獲得香港中文大學傳播學哲學碩士學位，於2004年獲得中國傳媒大學新聞學學士學位。"
      },
      {
        "name": "蘇波",
        "head_url": "subo",
        "title": "首席投資官兼執行董事",
        "sketch": "蘇先生主要負責監管本集團策略發展及擴張規劃。",
        "content": "蘇波先生為本集團創辦人兼首席投資官及本公司執行董事。蘇先生主要負責監管本集團策略發展及擴張規劃。彼於2018年5月7日獲委任為董事並自2018年9月20日起擔任首席投資官職務。彼於本集團歷任多個其他職務，包括本集團監督財務、法務及行政事務的負責人及自2018年2月起任本集團首席投資官。蘇先生在遊戲行業積逾8年經驗。創辦本集團之前，蘇先生於2007年7月至2009年11月在長春安信電力科技有限公司任程序員。自2017年9月起，蘇先生亦在長春工業大學擔任研究生導師。蘇先生於2007年6月透過遠程學習完成中國吉林大學計算機軟件專業本科學業。"
      },
      {
        "name": "郭順順",
        "head_url": "guoshunshun",
        "title": "首席產品官",
        "sketch": "郭先生主要負責監督遊戲開發及製作。",
        "content": "郭順順先生為本集團首席產品官及本公司執行董事。郭先生主要負責監督遊戲開發及製作。彼於2012年12月加入本集團並於本集團歷任多個職務，包括自2012年12月起任吉林省鑫澤網絡技術有限公司的遊戲設計及開發主管及自2015年9月起任職於家鄉互動（廈門）網絡科技有限公司董事會及棋牌遊戲業務部。彼於2018年9月20日獲委任為董事並自同日起擔任首席產品官職務。郭先生在遊戲行業積逾8年經驗。加入本集團之前，郭先生於2009年11月至2010年4月在吉林省睿網科技股份有限公司任程序員。"
      },
      {
        "name": "李偉",
        "head_url": "liwei",
        "title": "董事長顧問",
        "sketch": "李先生主要負責協助本集團首席執行官執行本集團的整體發展策略及業務規劃",
        "content": "李偉先生為本集團董事長顧問。彼負責協助本集團首席執行官執行本集團的整體發展策略及業務規劃。李先生於2013年8月加入本集團並於本集團歷任多個職務，包括吉林省鑫澤網絡技術有限公司的營銷總監。彼積逾12 年銷售管理經驗。加入本集團之前，李先生於2005年5月至2006年12月任北京易彩互聯科技有限公司的銷售經理。2007年1月至2008年3月，彼為亞洲金控（北京）投資有限公司的銷售發展董事。2010年12月至2013年7月，李先生於耐特康賽網絡技術（北京）有限公司任業務發展董事。李先生於2007年1月取得北京物資學院的電子商務文憑。"
      },
      {
        "name": "吳介然",
        "head_url": "",
        "title": "投資總監兼聯席公司秘書",
        "sketch": "",
        "content": "<p>吳介然先生，34歲，於2021年3月加盟本集團擔任戰略投資部的投資總監，負責本公司的資本市場事務、策略投資與收購、財務投資及被投企業財務法務綜合管理。 吳先生在財務管理、策略投資及風險管理領域擁有12年的工作經驗。 於加盟本集團前，吳先生曾擔任易澤資本管理有限公司的風控總監，負責公司擬投資項目的盡調及風險評估工作，以及全面管理被投企業的財務、法務事宜。 於2011年至2016年期間，彼於普華永道上海分所擔任稽核部經理，基於新舊中國企業會計準則、國際及香港企業會計準則、美國會計準則等，為美股、港股上市公司、並購交易標的公司及擬上市公司提供稽核服務。</ p><br/>吳先生於2011年取得上海財經大學的管理學學士學位。 於2016年，彼通過中國證券投資基金業協會組織的基金從業人員資格考試。 於2017年成為中國註冊會計師協會會員。"
      }
    ]
  },
  en: {
list:[
  {
    "name": "Wu Chengze (吳承澤)",
    "head_url": "wuchengze",
    "title": "Chairman, Chief Executive Officer and executive Director",
    "sketch": "Mr. Wu is primarily responsible for formulating and implementing the overall development strategies and business plans of our Group and overseeing the overall development and operations of our Group.",
    "content": "Mr. Wu Chengze (吳承澤) is a Founder, the Chairman and the Chief Executive Officer of our Group and an executive Director of our Company. Mr. Wu is primarily responsible for formulating and implementing the overall development strategies and business plans of our Group and overseeing the overall development and operations of our Group. He was appointed as a Director on May 7, 2018 and his position as Chairman and Chief Executive Officer took effect on September 20, 2018. He has held various other positions in our Group, including chief operating officer of our Group prior to 2014 and the general manager of Jiaxiang Interactive (Xiamen) Network Technology Company Limited since September 2015. Mr. Wu has over eight years of experience in the game industry. Prior to founding our Group, Mr. Wu worked at Changchun Anxin Power Technology Co., Ltd. (長春安信電力科技有限公司) as a programmer from August 2006 to September 2009. Mr. Wu completed his master’s degree in law in July 2009 at Jilin University (吉林大學) in the PRC."
  },
  {
    "name": "Tang Yinghao  (湯英浩)",
    "head_url": "subo",
    "title": "Chief Investment Officer and executive Director",
    "sketch": "",
    "content": "Mr. Tang Mr. Tang, aged 36, obtained the Bachelor’s degree in Commerce (Accounting and Finance)  as well as Master’s degrees in Business and Government and Commercial Law from the  Australian National University in 2008, 2009 and 2010, respectively. Mr. Tang served at  the National Australian Bank Financial Planning as Head of Operations from 2011 to 2016  and worked with National Australian Bank Business as Asian Investment Lead from 2015  to 2018. He later joined Homeland Interactive Technology Ltd. as the financial director and  has been appointed as the Chief Financial Officer on 28 September 2020. Mr. Tang assisted  the Group to complete the listing on the Main Board of the Hong Kong Stock Exchange  on 4 July 2019. Since then, the Board has successively appointed Mr. Tang as a director of  two Hong Kong subsidiaries of the Group, namely Homeland Entertainment & Technology  Limited (家鄉互娛有限公司) and Homeland Interactive Entertainment & Technology  Limited (家鄉互動互娛有限公司)."
  },
  {
    "name": "Ding Chunlong (丁春龍)",
    "head_url": "",
    "title": "Corporate Advisor and Executive Director",
    "sketch": "",
    "content": "Mr. Ding, aged 35, obtained his certificate of specialization from Changchun University of  Technology (長春理工大學) in the PRC. Mr. Ding has over 6 years of experience in game  operation and new media marketing and has served as a consultant of the Group. From  March 2017 to June 2022, Mr. Ding worked with Jilin Yuke Network Technology Company  Limited as a consultant, and was responsible for the company’s overall operation. Since July  2022, Mr. Ding has been a consultant at Yutai (Shenzhen) Network Technology Co., Ltd.*  (豫泰(深圳)網路科技有限公司), primarily responsible for the application and research of  the Group’s new media operation, live entertainment and infomercial short video production  business."
  },
  {
    "name": "Cui Wei (崔瑋)",
    "head_url": "",
    "title": "Chief Executive Officer",
    "sketch": "",
    "content": "Ms. Cui, aged 41, was as a joint company secretary of the Company from 7 May 2021  to 4 September 2023. Ms. Cui joined the Group in March 2021 as the chief strategy officer of the Group  and was appointed as the joint company secretary in May 2021, responsible for the  Company’s capital markets, investments, mergers and acquisitions matters. Ms. Cui  has also served as the general manager of the Group’s Shenzhen branch since 2022,  leading multiple business sectors such as domestic and overseas innovative game  research and development. – 2 – From 2014 to 2020, Ms. Cui served as the senior vice president of Mango Investment  (芒果文創基金)* (EZY Capital), engaging in private equity investment. From 2007 to  2014, Ms. Cui worked at Advertising Centre of Hunan Radio and Television Station. Ms. Cui obtained her master’s degree in Communication from The Chinese University  of Hong Kong in 2007 and her bachelor’s degree in journalism from Communication  University of China in 2004."
  },
  {
    "name": "Su Bo (蘇波)",
    "head_url": "subo",
    "title": "Chief Investment Officer",
    "sketch": "Mr. Su is primarily responsible for overseeing and managing the strategic development and expansion plan of our Group.",
    "content": "Mr. Su Bo (蘇波) is a Founder and the Chief Investment Officer of our Group. Mr. Su is primarily responsible for overseeing and managing the strategic development and expansion plan of our Group. He was appointed as a Director on May 7, 2018 and his position as Chief Investment Officer took effect on September 20, 2018. He has held various other positions in our Group, including officer responsible for overseeing the financial, legal and administrative matters of our Group and chief investment officer of our Group since February 2018. Mr. Su has over eight years of experience in the game industry. Prior to founding our Group, Mr. Su worked at Changchun Anxin Power Technology Co., Ltd. (長春安信電力科技有限公司) as a programmer from July 2007 to November 2009. Since September 2017, Mr. Su has also been a mentor of post-graduate students at Changchun University of Technology (長春工業大學). Mr. Su completed his bachelor’s studies in computer software (through distance learning) from Jilin University (吉林大學) in the PRC in June 2007."
  },
  {
    "name": "Guo Shunshun (郭順順)",
    "head_url": "guoshunshun",
    "title": "Chief Product Officer",
    "sketch": "Mr. Guo is primarily responsible for overseeing the development and production of our games.",
    "content": "Mr. Guo Shunshun (郭順順) is the Chief Product Officer of our Group and an executive Director of our Company. Mr. Guo is primarily responsible for overseeing the development and production of our games. He joined our Group in December 2012 and has held various positions in our Group, including head of game design and development of Jilin Xinze since December 2012 and director of the board and card game business department of Jiaxiang Interactive since September 2015. He was appointed as a Director on September 20, 2018 and his position as Chief Product Officer took effect on the same date. Mr. Guo has over eight years of experience in the game industry. Prior to joining our Group, Mr. Guo worked at Jilin Reawin Technology Co., Ltd. (吉林省睿網科技股份有限公司) as a programmer from November 2009 to April 2010."
  },
  {
    "name": "Li Wei (李偉)",
    "head_url": "liwei",
    "title": "Chairman's Advisor",
    "sketch": "Mr. Li is responsible for assisting the Chief Executive Officer of our Group in executing the overall development strategies and business plans of our Group.",
    "content": "Mr. Li Wei (李偉), aged 35, is the advisor to the Chief Executive Officer of our Group. He is responsible for assisting the Chief Executive Officer of our Group in executing the overall development strategies and business plans of our Group. Mr. Li joined our Group in August 2013 and has held various positions in our Group, including marketing director at Jilin Xinze Network Technology Company Limited. He has over 12 years of experience in sales management. Prior to joining our Group, Mr. Li served as sales manager at Beijing Yicai Internet Technology Co., Ltd. (北京易彩互聯科技有限公司) from May 2005 to December 2006. From January 2007 to March 2008, he was the sales development director of Asian Financial (Beijing) Investment Co. Ltd. (亞洲金控（北京）投資有限公司). From December 2010 to July 2013, Mr. Li worked at Netconcepts Internet Technology (Beijing) Co., Ltd. (耐特康賽網絡技術（北京）有限公司) as business development director. Mr. Li received a diploma in e-commerce from Beijing Wuzi University (北京物資學院) in the PRC in January 2007."
  },
  {
    "name": "Wu Jieran(吳介然)",
    "head_url": "",
    "title": "Investment Director and Joint Company Secretary",
    "sketch": "",
    "content": "Mr. Wu, aged 34, joined the Company in March 2021 as the Investment Director of the  Strategic Investment Department, responsible for the Company’s capital market affairs,  strategic investments and acquisitions, financial investments, and comprehensive financial  and legal management of invested subsidiaries. Mr. Wu has 12 years of working experience  in the fields of financial management, strategic investment and risk management. Before joining the Group, Mr. Wu served as the risk control director of EZY Capital and is  responsible for the due diligence and risk assessment of the company’s proposed investment  projects, as well as the overall management of the financial and legal affairs of the invested  companies. From 2011 to 2016, he served as the manager of the Audit Department at PWC  Shanghai Branch. During his tenure at PWC, Mr. Wu participated in numerous M&A  transactions and provided audit services to U.S. and Hong Kong-listed companies based on  the old and new Chinese Accounting Standards for Business Enterprises, international and  Hong Kong Accounting Standards for Business Enterprises, US Accounting Standards, etc. – 2 – Mr. Wu obtained a bachelor’s degree in management from Shanghai University of Finance  and Economics in 2011. In 2016, Mr. Wu passed the fund practitioner qualification  examination organized by the Asset Management Association of China. Later, Mr. Wu  became a member of the Chinese Institute of Certified Public Accountants in 2017."
  },
],
title:"Management Team"
  },
};

export const mail = {
  cn: {
    title: "邮箱",
    content: "hr@weile.com  IR@weile.com",
  },
  tcn: {
    title: "邮箱",
    content: "hr@weile.com  IR@weile.com",
  },
  en: {
    title: "Mail",
    content: "hr@weile.com  IR@weile.com",
  },
};

export const development_history = [
  {
    "year": "2015",
    "list": [
      {
        "day": "8月31日",
        "content": "家乡互动（厦门）网络科技有限公司成立"
      },
      {
        "day": "9月",
        "content": "推出独立游戏品牌微乐游戏并正式运营"
      }
    ]
  },
  {
    "year": "2016",
    "list": [
      {
        "day": "7月",
        "content": "微乐贵阳捉鸡、微乐江西麻将等明星产品陆续上线"
      },
      {
        "day": "12月",
        "content": "本地化手机麻将游戏增至70款覆盖中国十多个省份不同城市"
      }
    ]
  },
  {
    "year": "2017",
    "list": [
      {
        "day": "3月",
        "content": "引入免房卡玩法"
      },
      {
        "day": "12月",
        "content": "累计注册玩家超过5000万"
      }
    ]
  },
  {
    "year": "2018",
    "list": [
      {
        "day": "5月",
        "content": "家乡互动（厦门）网络科技有限公司党支部成立"
      },
      {
        "day": "8月",
        "content": "家乡互动（厦门）网络科技有限公司工会成立"
      },
      {
        "day": "12月",
        "content": "累计注册玩家超过1.2亿"
      }
    ]
  },
  {
    "year": "2019",
    "list": [
      {
        "day": "1月",
        "content": "发布运营46款微信小程序的棋牌游戏"
      },
      {
        "day": "7月4日",
        "content": "家乡互动（厦门）网络科技有限公司在香港联合交易所挂牌上市"
      },
      {
        "day": "9月",
        "content": "拓展广告收入业务"
      },
      {
        "day": "截至2019年10月",
        "content": "公司研发运营手机游戏511款，其中469款麻将游戏、34款扑克类游戏、8款休闲益智游戏"
      }
    ]
  },
  {
    "year": "2021",
    "list": [
      {
        "day": "1月",
        "content": "游戏玩法突破600款"
      },
      {
        "day": "4月",
        "content": "迭代上线两款精品捕鱼游戏"
      },
    ]
  },
  {
    "year": "2022",
    "list": [
      {
        "day": "1月",
        "content": "累计注册玩家突破4亿"
      },
      {
        "day": "2月",
        "content": "日活跃玩家突破1500万"
      },
    ]
  },
  {
    "year": "2023",
    "list": [
      {
        "day": "5月",
        "content": "上线精品塔防休闲游戏《遇见喵克斯》"
      },
    ]
  }
]

export const honors = [
  {
      "title": "荣誉奖章",
      "content": "家乡互动（厦门）网络科技有限公司经过多年经营得到了社会各界的认可，目前获得福建省科技小巨人领军企业、厦门市科技小巨人领军企业、厦门市小巨人科技型中小企业、第五届金茶奖2017年度最佳棋牌游戏企业、2017年度纳税大户、2018年度超1千万元纳税特大户、竞技二打一项目赛事合作伙伴等诸多荣誉证书",
      "pics": [
          {
              "title": "2017年度纳税大户",
              "url": "honors1.jpg"
          },
          {
              "title": "厦门市科技小巨人领军企业",
              "url": "honors2.jpg"
          },
          {
              "title": "2018年超1千万元纳税特大户",
              "url": "honors3.jpg"
          },
          {
              "title": "福建省科技小巨人领军企业",
              "url": "honors4.jpg"
          },
          {
              "title": "阿里云最佳云上架构奖",
              "url": "honors5.jpg"
          },
          {
            "title": "守合同重信用企业",
            "url": "honors6.jpg"
        },
        {
            "title": "2022年度超6000万元纳税特大户",
            "url": "honors7.jpg"
        },
        {
            "title": "厦门市新经济领军企业",
            "url": "honors8.jpg"
        },
        {
            "title": "2023年福建省互联网综合实力前50家企业",
            "url": "honors9.jpg"
        },
        {
            "title": "福建省创新型民营企业100强",
            "url": "honors10.jpg"
        },
        {
          "title": "最具成长性文化企业",
          "url": "honors11.jpg"
      }
      ]
  }
]
