import { render, staticRenderFns } from "./CompInformation.vue?vue&type=template&id=8a73ebfa&scoped=true"
import script from "./CompInformation.vue?vue&type=script&lang=js"
export * from "./CompInformation.vue?vue&type=script&lang=js"
import style0 from "./CompInformation.vue?vue&type=style&index=0&id=8a73ebfa&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a73ebfa",
  null
  
)

export default component.exports