<template>
	<div class="row">
	<div class="news-detail-title">
		<h2>服务条款</h2>
	</div>
	<div class="news-detail-info">
	</div>
	<div class="news-detail-body">
		
		<strong class="green">一、用户须知</strong>
		<p>&nbsp;</p>
		<p>作为网络游戏，《微乐游戏中心》拥有完善的游戏机制和强大的运行能力。为了各位用户能够更好的享受《微乐游戏中心》中的每个精彩时刻，您应该遵守以下守则：</p>
		<p>&nbsp;</p>
		<p>一、用户之间人人平等，这是我们对您进行服务的准则。我们努力使每个用户获得同等质量的服务，您也 应该做到尊重其他用户的权利，不进行任何可能会侵害其他用户游戏质量的活动。</p>
		<p>二、网络游戏基于人人互动才能精彩，为了使您在虚拟世界中拥有更多的朋友，请在游戏中注意言行举止，礼貌用语。对于语言污秽的用户，微乐游戏的管理员在获得举报后将会采取禁言等措施进行管理。</p>
		<p>三、公平游戏是享受游戏的前提，正如每一个人都知道，任何程序都存在BUG，虽然我们已经解决了所有已知的BUG，但是不能排除其他BUG存在的可能性。作为游戏用户，应该能够在发现BUG时主动向微乐游戏的管理员汇报。任何用户无论在任何状态下都严禁利用任何微乐游戏可能出现的BUG进行非正常性的获利，这些获利包括但不限于获得额外的虚拟货币，道具物品等。一经发现，微乐游戏的管理员将会采取清除异常数据、封停账号等措施，情节严重的，将用户信息等相关内容上报公安机关，因此给微乐游戏带来的任何损失，将由用户承担责任，微乐游戏有权通过正当的法律途径对此类用户按照相关法律法规追究其民事/刑事责任。</p>
		<p>四、用户应当自觉不参与以任何用真实货币或物品换取虚拟物品、游戏账号等交易行为，如您参与类似交易行为造成任何损失，我们不对这类交易中产生的任何问题进行支持或者保障。</p>
		<p>五、为了保证游戏公平性，微乐游戏的管理员将不会介入到任何用户之间纠纷中去。</p>
		<p>六、每个用户均有请求游戏管理人员帮助的权利。为了能够让更多的用户在游戏遇到问题后可以第一时间获得帮助，每个用户应该自觉不与游戏管理人员闲聊。游戏管理人员有权利不回答与工作无关的闲聊话题。</p>
		<p>七、每个用户均有监督游戏管理人员的权利，如果您发现游戏管理人员任何违规行为，均可以采用游戏提供的截图方式（保障图片真实性）获取现场，并将投诉内容通过客服提交，我们的纪律检查部门将会对该名游戏管理员进行检查。</p>
		<p>八、理解并且遵守微乐游戏颁布的用户守则和服务承诺以及其他规定。</p>
		<p>&nbsp;</p>
		<strong class="green">二、特别提示：</strong>
		<p>&nbsp;</p>
		<p>"微乐游戏"同意按照本协议的规定提供基于互联网的相关服务(以下称"网络服务")，为获得网络服务，服务使用人(以下称"用户")应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序和游戏过程。用户在进行注册后及游戏过程中即表示用户完全接受本协议项下的全部条款。这些条款可由"微乐游戏"随时更新，《微乐游戏棋牌游戏中心》服务协议一旦发生变动，"微乐游戏"将会在相关的页面上提示修改内容。修改后的服务协议一旦在页面上公布即有效代替原来的服务协议。用户在使用《微乐游戏中心》提供的各项服务之前，应仔细阅读本服务协议，如用户不同意本服务协议及"微乐游戏"随时对其内容的修改，用户可以主动取消微乐游戏提供的服务。</p>
		<p>&nbsp;</p>
		<p>1. 释义:</p>
		<p>本用户服务条款是由用户与"微乐游戏"所订立的相关权利义务规范。因此，请于注册成为《微乐游戏中心》用户前，确实详细阅读本用户服务条款的所有内容，当您注册账户或执行《微乐游戏中心》线上游戏即视为同意接受本用户服务条款的所有规范并愿受其约束。</p>
		<p>本用户服务条款所指线上游戏是指用户需要通过计算机等电子设备与服务器联机才可以执行的，由微乐游戏全权运营的多人互动游戏软件《微乐游戏中心》，《微乐游戏中心》包括但不限于：一个单独的计算机程序，其名称或标题为：《微乐游戏中心》以及相关的书面文档、图片文件、影片、用户手册（载明软件程序的安装与应用方法）以及与《微乐游戏中心》相关的说明书、商标、标识以及任何其他的美术品；以上统称为《微乐游戏中心》。</p>
		<p>用户是指：愿意通过"微乐游戏"提供的游戏途径获得许可（账号和密码）执行线上游戏的个人。</p>
		<p>&nbsp;</p>
		<p>2. 著作权声明：</p>
		<p>《微乐游戏中心》的所有相关著作权、专利权、商标、营业秘密及其它任何所有权或权利，均属"微乐游戏"或其原始授权人所有。非经微乐游戏或原始授权人的同意，任何人或用户均不得擅自下载、重制、传输、改作、编辑于任何以线上游戏为目的以外的使用及任何以获利为目的的使用，否则应负所有法律责任。《微乐游戏中心》网络游戏运营过程中产生并储存于微乐游戏数据库的任何数据信息（包括但不限于账号数据信息、角色数据信息、等级物品数据信息等，但用户的姓名、身份证号、电话号码等个人身份数据信息除外）的所有权均属于微乐游戏，用户在按照本用户服务条款正常使用《微乐游戏中心》网络游戏的过程中对属于其用户账号的数据信息享有有限使用权。</p>
		<p>&nbsp;</p>
		<p>3. 用户的基本义务：</p>
		<p>用户需自行配备联机执行线上游戏所需的各项计算机及网络设备，并自行负担上网所需的各项费用。</p>
		<p>"微乐游戏"向用户提供网络游戏《微乐游戏中心》本身属于商业行为，用户有义务根据微乐游戏的相关规定而支付相应的费用。</p>
		<p>&nbsp;</p>
		<p>4. 真实登录个人资料并更新</p>
		<p>用户同意在注册时提供完整、详尽、真实的个人资料，若所提供的资料于日后有变更者，应随时在线上进行更新。若用户所提供的资料与事实不符或所提供的资料已变更而未及时更新或有任何误导之嫌疑，导致微乐游戏无法为用户提供或进一步提供服务，微乐游戏不会因此承担任何责任。</p>
		<p>&nbsp;</p>
		<p>5. 用户保管账号及密码的义务</p>
		<p>5.1 用户有义务妥善保管在注册并使用《微乐游戏中心》时获得的账号及密码，并为此组账号及密码登入系统后所开始的一连串的行为或活动负责。任何以用户账号所进行的网上操作均视同为用户的有效操作行为，由此完成的网上操作指令的结果、网上在线的结果或由此造成的损失，由用户承担全部责任。</p>
		<p>5.2 为维护自身权益，用户不应将账号及密码泄漏或提供第三人知悉、出借或者转让他人使用。</p>
		<p>5.3 若用户发现账号或密码遭他人非法使用或有异常使用的情形，应当立即通知微乐游戏客户中心进行处理。</p>
		<p>&nbsp;</p>
		<p>6. 隐私权保护
			<a name="privacy"></a>
		</p>
		<p>6.1 保护用户（特别是未成年人）的隐私是微乐游戏的一项基本政策，因此，若父母（监护人）希望未成年人（尤其是十岁以下子女）得以使用本服务，必须以父母（监护人）名义申请注册，在接受本服务时，应以法定监护人身份加以判断本服务是否符合未成年人。微乐游戏保证不对外公开或向第三方（本条所列情况除外）提供用户注册资料及用户在使用网络服务时存储在微乐游戏的非公开内容，但下列情况除外：</p>
		<p>（A）事先获得用户的明确授权；</p>
		<p>（B）根据有关的法律法规要求；</p>
		<p>（C）按照相关政府主管部门的要求；</p>
		<p>（D）为维护社会公众的利益；</p>
		<p>（E）为维护微乐游戏的合法权益</p>
		<p>6.2 为严格保护您的个人信息安全，我们将使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄漏。</p>
		<p>6.3 微乐游戏可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与微乐游戏同等的保护用户隐私的责任，则微乐游戏可将用户的注册资料等提供给该第三方。</p>
		<p>6.4 在不透露单个用户隐私资料的前提下，微乐游戏有权对整个用户数据库进行技术分析并对其进行分析、整理后的用户数据进行商业上的利用。尽管微乐游戏对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。</p>
		<p>6.5 微乐游戏非常重视对青少年个人信息的安全和保护。微乐游戏建议任何18周岁以下的未成年人，都不要以各种网络游戏作为娱乐的方式。</p>
		<p>6.6 关于免责，就下列相关事宜的发生，微乐游戏不承担任何法律责任：</p>
		<p>（1）由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏， 或其他非因微乐游戏原因导致的个人信息的泄漏；</p>
		<p>（2）任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；</p>
		<p>（3）因不可抗力导致的任何后果</p>
		<p>&nbsp;</p>
		<p>7. 用户使用线上游戏的限制</p>
		<p>7.1 用户使用微乐游戏的线上游戏，除应遵守法律、本用户服务条款的规范外，并应遵守"《微乐游戏中心》用户守则"，否则微乐游戏有权随时按照该守则处分或终止用户使用线上游戏，由此造成的任何损失等微乐游戏不负任何责任。</p>
		<p>7.2 微乐游戏严禁用户利用线上游戏的机会做与游戏无关的行为，包括但不限于妨害他人名誉或隐私权；使用自己、匿名、冒用他人或微乐游戏名义散播诽谤、不实、威胁、不雅、猥亵、不法、攻击性或侵害他人权利的消息或文字，传播色情及其它违反社会公德的言论；传输或散布计算机病毒；从事广告或贩卖商品；从事不法交易、张贴虚假不实或引人犯罪的信息；任何违反中华人民共和国法律及其它法令的行为，亦不得利用线上游戏的机会，与其它用户、他人进行非法的交涉或对话。</p>
		<p>7.3 用户对微乐游戏的游戏管理人员所进行的询问应据实澄清，否则微乐游戏有权随时终止用户使用线上游戏；微乐游戏发现不法或可疑的行为者，微乐游戏有权随时通报司法机关进行处理，并有权同时向该机关陈报用户的基本资料及行为，供该机关调查。用户就此所产生的不便或困扰，不得向微乐游戏请求任何损害或补偿。</p>
		<p>7.4 用户应就其在线上游戏的行为及活动自负责任，微乐游戏仅提供线上游戏予用户自行执行或与其它用户依照游戏设定的方式进行竞赛或游戏。微乐游戏就用户在线上游戏的行为、活动及交易不负任何责任。</p>
		<p>&nbsp;</p>
		<p>8. 服务的停止和更改</p>
		<p>8.1 发生下列情形之一时，微乐游戏有权停止或中断线上游戏：</p>
		<p>8.1.1 对于微乐游戏的网络设备进行必要的保养及施工；</p>
		<p>8.1.2 发生突发性的网络设备故障时；</p>
		<p>8.1.3 由于微乐游戏所用的网络通信设备由于不明原因停止，无法提供服务时；</p>
		<p>8.1.4 由于不可抗力因素致使微乐游戏无法提供线上游戏服务；</p>
		<p>8.2 除此之外，微乐游戏有权停止、更改各项服务内容或终止任意用户账号，但需在《微乐游戏中心》官方网站www.weile.com首页提前24小时通知，用户违反用户守则的处理不在此例。无论任何情形，微乐游戏就停止、更改或终止用户账号所可能产生的不便及损害，微乐游戏对用户本人或任何第三人均不负任何损害赔偿责任。</p>
		<p>8.3 用户应了解并同意，微乐游戏可能因公司本身、其它协力厂商或相关电信业者网络系统软硬件设备的故障、失灵或人为操作的疏失而全部或一部分中断、暂时无法使用、迟延或因他人侵入微乐游戏系统篡改或伪造变造资料等，造成线上游戏的停止或中断者，用户不得要求微乐游戏提供任何的补偿或赔偿。</p>
		<p>8.4 《微乐游戏中心》道具为增加游戏乐趣而设置并随游戏的停止运营而消失，用户一经购买将不得以任何形式退还给微乐游戏。鉴于网络游戏的特殊性质，道具仅在游戏的运营期内有效。</p>
		<p>&nbsp;</p>
		<p>9. 风险承担</p>
		<p>用户同意使用微乐游戏线上游戏软件《微乐游戏中心》，是出于用户个人意愿，并愿自负任何风险。包括但不限于其因执行《微乐游戏中心》或自行由《微乐游戏中心》官方网站下载游戏、资料图片而导致用户或其所使用的计算机系统损害，或发生任何资料的流失等。</p>
		<p>&nbsp;</p>
		<p>10. 责任的免除</p>
		<p>微乐游戏就线上游戏《微乐游戏中心》，不负任何明示或默示的担保责任，亦不保证《微乐游戏中心》的稳定、安全、无误及不中断。用户应自行承担执行线上游戏所有的风险及因此可能致生的损害，包括而不限于其因执行线上游戏《微乐游戏中心》或自行由《微乐游戏中心》官方网站下载游戏或资料图片而导致用户或其所使用的计算机系统损害，或发生任何资料的流失等。</p>
		<p>&nbsp;</p>
		<p>11. 赔偿责任的排除及限制</p>
		<p>"微乐游戏"对于用户使用线上游戏《微乐游戏中心》或无法使用线上游戏所产生的任何直接、间接、衍生的损害或所失利益不负任何损害赔偿责任。若依法无法完全排除损害赔偿责任时，微乐游戏的赔偿责任亦以用户就使用线上游戏所支付微乐游戏的价款为限。</p>
		<p>&nbsp;</p>
		<p>12. 链接</p>
		<p>"微乐游戏"在其《微乐游戏中心》官方网站的所有网页上所提供的所有链接，可能链接到其它个人、公司或组织的网站，提供该等网站的目的，是便利用户自行搜寻或取得信息，微乐游戏对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度。这些个人、公司或组织与微乐游戏亦不存在任何雇用、委任、代理、合伙或其它类似的关系。</p>
		<p>&nbsp;</p>
		<p>13. 微乐游戏的终止权</p>
		<p>用户应确实遵守本用户服务条款及有关法律命令的规定。微乐游戏对于用户是否违反服务条款有最终决定权。若微乐游戏认定用户违反服务条款或任何法令者，用户同意微乐游戏可以随时终止用户的账号及密码使用权。</p>
		<p>用户若违反服务条款或相关法令，导致微乐游戏或其关系企业、受雇人、受托人、代理人或其它相关履行辅助人因此而受到损害或支出费用（包括但不限于因进行民刑事或行政程序所支出的律师费用），用户须负担损害赔偿责任。</p>
		<p>&nbsp;</p>
		<p>14. 停止或变更服务</p>
		<p>14.1 微乐游戏取消或停止用户的资格或加以限制，用户不得要求补偿或赔偿。</p>
		<p>14.2 微乐游戏保留将来新增、修改或删除线上游戏的全部或部分的权利，且不再另行个别通知，用户不得因此要求任何补偿或赔偿。</p>
		<p>14.3 微乐游戏有权删除超过6个月不上线的用户，且不再另行个别通知，用户不得因此要求任何补偿或赔偿。</p>
		<p>&nbsp;</p>
		<p>15. 广告信息或促销计划</p>
		<p>"微乐游戏"的线上游戏软件上可能刊登商业广告、或其它活动促销的广告。这些内容系广告商或商品服务提供者所为，微乐游戏仅提供刊登内容的媒介。用户通过微乐游戏或其所链接的网站所购买的服务或商品，其交易行为仅存于用户与该商品或服务的提供者之间，与微乐游戏无关。</p>
		<p>&nbsp;</p>
		<p>16. 服务条款的修改</p>
		<p>由于用户及市场状况的不断变化，微乐游戏保留随时修改本用户服务条款的权利，修改本用户服务条款时，微乐游戏将于《微乐游戏中心》官方网站首页公告修改的事实，而不在另行对用户进行个别通知。若用户不同意修改的内容，可停止使用微乐游戏的线上游戏。若用户继续使用微乐游戏的线上游戏，即视为用户业已接受微乐游戏所修订的内容。</p>
		<p>&nbsp;</p>
		<p>17. 个别条款的效力</p>
		<p>本用户服务条款所定的任何条款的一部或全部无效者，不影响其它条款的效力。</p>
		<p>&nbsp;</p>
		<p>18. 法律适用及纠纷解决</p>
		<p>本用户服务条款的解释、效力及纠纷的解决，适用于中华人民共和国的法律法规。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；如协商不成，任何与本服务协议有关的争议均由微乐游戏所在地的人民法院管辖。</p>
	</div>
	</div>
</template>

<script>
	export default {
		name: 'situation',
	}
</script>

<style  scoped="scoped">
	.row{
		margin: 0 auto 80px;
		padding: 15px 100px;
    font-size: 17px;
	}

</style>
